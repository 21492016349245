import React from 'react'
import { Link } from 'gatsby'
import { Button } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Section from '@components/Section'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'

const Privacy = props => (
  <Layout {...props}>
    <Seo title='Politique de confidentialité' />
    <Divider />
    <Stack>
      <Main>
        <Section>
          <PageTitle
            header='Politique de confidentialité'
            subheader='Dernier mise à jour : 19 septembre, 2022'
          />
          <h3 id='données-personnelles'>Données personnelles</h3>
          <p>
            Sur le site web TheCode.fr, il y a 2 types de données susceptibles
            d’être recueillies :
          </p>
          <ul>
            <li>
              <strong>Les données transmises directement</strong>
              Ces données sont celles que vous nous transmettez directement, via
              un formulaire de contact ou bien par contact direct par email.
              Sont obligatoires dans le formulaire de contact le champs « prénom
              et nom », « entreprise ou organisation » et « email ».
            </li>
            <li>
              <strong>Les données collectées automatiquement</strong>Lors de vos
              visites, une fois votre consentement donné, nous pouvons
              recueillir des informations de type « web analytics » relatives à
              votre navigation, la durée de votre consultation, votre adresse
              IP, votre type et version de navigateur. La technologie utilisée
              est le cookie.
            </li>
          </ul>
          <h3 id='utilisation-des-données'>Utilisation des données</h3>
          <p>
            Les données que vous nous transmettez directement sont utilisées
            dans le but de vous re-contacter et/ou dans le cadre de la demande
            que vous nous faites. Les données « web analytics » sont collectées
            de forme anonyme (en enregistrant des adresses IP anonymes) par
            Google Analytics, et nous permettent de mesurer l&#39;audience de
            notre site web, les consultations et les éventuelles erreurs afin
            d’améliorer constamment l’expérience des utilisateurs. Ces données
            et ne seront jamais cédées à un tiers ni utilisées à d’autres fins
            que celles détaillées ci-dessus.
          </p>
          <h3 id='base-légale'>Base légale</h3>
          <p>
            Les données personnelles ne sont collectées qu’après consentement
            obligatoire de l’utilisateur. Ce consentement est valablement
            recueilli (boutons et cases à cocher), libre, clair et sans
            équivoque.
          </p>
          <h3 id='durée-de-conservation'>Durée de conservation</h3>
          <p>
            Les données seront sauvegardées durant une durée maximale de 3 ans.
          </p>

          <script
            data-cfasync='false'
            src='/cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js'
          ></script>
        </Section>
        <Section>
          <Button variant='primary' as={Link} to='/'>
            Page d'accueil
          </Button>
        </Section>
      </Main>
    </Stack>
  </Layout>
)

export default Privacy
